import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Fab from '@mui/material/Fab';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
// import { Facebook, Instagram, LinkedIn  } from "@mui/icons-material";
import YouTubeIcon from '@mui/icons-material/YouTube';
import GoogleIcon from '@mui/icons-material/Google';
import { Box } from "@mui/material";
import { NavLink } from 'reactstrap';
// import { Link } from 'react-router-dom';



export default function Footer() {
    return (
        <>
            <Link href="https://wa.me/15517990700" target='_blank' color="inherit" sx={{ marginRight: 0.5 }} className="d-flex justify-content-end align-items-start mb-3 me-5">
                <Fab style={{ backgroundColor: "rgb(221, 159, 0)", }} aria-label="chat">
                    <ChatBubbleIcon />
                </Fab>
            </Link>
            <Box mb={0}
                gutterBottom={0}
                component="footer"
                sx={{

                    position: 'relative',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '58px',


                    backgroundColor: '#CDCDCD'
                    ,
                    p: 1,
                    '@media (max-width: 600px)': {
                        height: '70px',
                    },
                }}
            >
                <Container maxWidth="xl">
                    <Grid container spacing={{ xs: 2, md: 5 }} justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start' }} alignItems={{ xs: 'flex-start', md: 'flex-start' }}>
                        <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex',alignItems: 'flex-start', paddingLeft: '48px',  }} className="justify-content-center justify-content-md-start justify-content-lg-start justify-content-xl-start">
                            {/* <Typography variant="body2" color="text.secondary" sx={{ fontSize: 12, marginRight: 1, marginTop: 0.5 }}>
                            Follow Us
                        </Typography> */}
                            <Link href="https://www.facebook.com/" color="inherit" target="_blank" >
                                <img src="/Facebook.svg" alt="Facebook Icon" style={{ width: "57px", height: '45px', gap: "0px", opacity: '0px' }} />
                            </Link>
                            <Link href="https://www.instagram.com/" color="inherit" target="_blank" >
                                <img src="/Instagram.svg" alt="Instagram Icon" style={{ width: "57px", height: '45px', gap: "0px", opacity: '0px' }} />
                            </Link>
                            <Link href="https://www.tiktok.com/" color="inherit" target="_blank" >
                                <img src="/TikTok.svg" alt="Tiktok Icon" style={{ width: "57px", height: '45px', gap: "0px", opacity: '0px' }} />
                            </Link>
                            <Link href="https://www.linkedin.com/" color="inherit" target="_blank" >
                                <img src="/LinkedIn.svg" alt="Linkedin Icon" style={{ width: "57px", height: '45px', gap: "0px", opacity: '0px' }} />
                            </Link>
                            <Link href="https://www.youtube.com/" target="_blank" color="inherit">
                                <img src="/YouTube.svg" alt="Youtube Icon" style={{ width: "57px", height: '45px', gap: "0px", opacity: '0px' }} />
                            </Link>
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6 }>
                        <Box>
                            <Typography variant="body2" color="text.secondary" align="center" sx={{ fontSize: 12, marginLeft: 1 }}>
                                {"Copyright © "}
                                {new Date().getFullYear()}
                                {" InstaRep. All rights reserved."}
                            </Typography>
                        </Box>
                    </Grid> */}
                    </Grid>
                </Container>
            </Box>
        </>
    );
}