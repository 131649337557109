import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import Role1 from '../Images/Role1.jpeg';
import Role2 from '../Images/Role2.jpeg';
import Role3 from '../Images/Role3.jpg';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import "./Offer.css";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

function ScrollToTopOnMount() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return null;
}

const Icon = styled.img`
  position: absolute;
  display: inline-block;
  top: 23px;
  left: 30px;
 height:43px;
 width:'25px';

  cursor: pointer;
  z-index: 3;

    @media (max-width: 767px) {
    font-size: 36px!important;
   top: 23px;
     left: 10px;
  }
`;
const Text = styled.p`
 position: absolute;
  font-size: 21px;
  margin-left: 14px;
   top: 29px;
  left: 46px;
  cursor: pointer;

    @media (max-width: 767px) {
    font-size: 16px;
 top: 32px;
     left: 20px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: gray;
  transition: color 0.3s;

  &:hover {
    color: black;
  }
`;

const StyledParagraph = styled.p`
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 24px;

    /* Media query for smaller screens */
  @media (max-width: 767px) {
    font-size: 18px;
  }

  /* Media query for medium screens */
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }

  /* Media query for large screens */
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 20px;
  }


`;

const StyledLine = styled.div`
  width: 95%;
  height: 1px;
  background-color: rgb(100, 149, 237);
  margin: 8px auto;

`;

const StyledBodyText = styled.p`
    font-family: Archivo, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
`;

const StyledTitle = styled.h2`
    font-family: Archivo, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    margin:0;
    display:flex;
    justify-content:center;
    align-items:center;
`;

const ElementsData = [
    {
        headingText: "FREE COUNSEL",
        imgSrc: "/Assets/IMG_9963 1.png",
        bodyText: "We start by understanding your specific needs; then our experts create tailored workflows, and HR drafts a precise job description for your approval.",
        star:false
    },
    {
        headingText: "BEST FIT TALENT",
        imgSrc: "/Assets/Analyze-rafiki 1.png",
        bodyText: "We provide expertly matched talent, perfectly aligned with your unique requirements.",
        star:false
    },
    {
        headingText: "SEAMLESS ONBOARDING",
        imgSrc: "/Assets/Office management Customizable Disproportionate Illustrations _ Cuate Style 1.png",
        bodyText: "Our streamlined onboarding ensures a hassle-free start for you from day one.",
        star:false
    },
    {
        headingText: "GROWTH",
        imgSrc: "/Assets/IMG_9961 2 1.png",
        bodyText: "Now is the right time to consider further growth.",
        star:true
    }
];

const LineConnector = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items:center;
  margin: 0;
`;

const Line = styled.div`
  width: 40%; // Adjust width for the line on each side of the circle
  height: 2px;
  background-color: #000;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #000;
  margin: 0;
`;

const CardConnector = () => {
    return (
        <LineConnector>
            <Line />
            <Circle />
            <Line />
        </LineConnector>
    );
};

export const SingleElement = ({ headingText, imgSrc, bodyText, star }) => {
    return (
        <div style={{ width: "200px", height: '400px', zIndex: 10 }} className="d-flex flex-column align-items-center justify-content-start gap-1 px-0 mx-0">
            <div className="d-flex align-items-center justify-content-center p-2" style={{ width: '100%', backgroundColor: '#1B2E35', color: "#fff", border: "1px solid #000000" }}>
                <StyledTitle>
                    {/* {star &&<img src="/Assets/Star 1.svg" width="21px" height="21px" />} */}
                    {headingText}
                </StyledTitle>
            </div>
            <div style={{ width: '100%', border: "1px solid #000000" }}>
                <img src={imgSrc} alt={headingText} width="200px" height="200px" style={{ objectFit: "cover" }} />
            </div>
            <div className="mt-2" style={{ width: '90%' }}>
                <StyledBodyText>{bodyText}</StyledBodyText>
            </div>
        </div>
    );
};

const HowItWorksComponent = () => {
    const navigate = useNavigate();

    return (
        <div style={{ flex: 1, minHeight: 'calc(100vh - 285px)', marginTop: '94px' }} className="d-flex flex-column align-items-center justify-content-center">
            <Container className="text-center d-flex align-items-center justify-content-center mt-2  mx-1">
                <Row className="col-12 d-flex flex-rowalign-items-center justify-content-center flex-wrap p-0">
                    {ElementsData.map((element, index) => (
                        <>
                            <Col xs={12} sm={12} md={5} lg={2} xl={2} key={index} className="d-flex justify-content-center mx-0 px-0">
                                <SingleElement
                                    headingText={element.headingText}
                                    imgSrc={element.imgSrc}
                                    bodyText={element.bodyText}
                                    star={element.star}
                                />
                            </Col>
                            {index < ElementsData.length - 1 && (
                                <Col lg={1} xl={1} className="d-none d-lg-flex d-xl-flex align-items-center justify-content-center mx-0 px-0" style={{ marginBottom: '150px' }}>
                                    <CardConnector />
                                </Col>
                            )}
                        </>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default HowItWorksComponent;