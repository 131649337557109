import React from 'react';
import NavMenu from './NavMenu';
import { Outlet } from 'react-router';
import GridLinks from "./GridLinks";
import ResponsiveNav from './ResponsiveNav';
/*const Layout = ({ children }) => {

    return (
       
        <div>

            <NavMenu />
   
            
                {children}
            </Container>
        </div>
    );
};*/


const WithNavLayout = ({ children }) => (
    <>
        <NavMenu />
        <ResponsiveNav />

        <Outlet />


    </>
);

const WithoutNavLayout = ({ children }) =>
(<>

    <Outlet />

</>
);

export { WithNavLayout, WithoutNavLayout };

