import React, { useState } from 'react';
import { NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import whatsapp from '../Images/q.jpeg';
import MenuIcon from '../Images/NavIcon.png';
import { useNavGrid } from '../Context/NavGridContext';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import { Button } from 'reactstrap';


const NavMenu = () => {
    const navigate = useNavigate();
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    const handleNavCollapse = () => {
        setIsNavCollapsed(!isNavCollapsed);
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleCloseSidebar = () => {
        setIsNavCollapsed(true);
        setIsSidebarVisible(false);
    };

    const logoStyle = {
        fontSize: '24px',
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        alignItems: 'center', // Align text vertically in the center
        '@media only screen and (max-width: 300px)': {
            display: 'none !important',
        },

    };

    const instaStyle = {
        fontWeight: 'normal', // Normal font weight for "insta"
        fontStyle: 'normal',
        '@media only screen and (max-width: 300px)': {
            display: 'none !important',
        },
    };

    const repStyle = {
        fontWeight: 'bold', // Bold font weight for "Rep"
        marginRight: '5px',
        fontStyle: 'normal',


        '@media only screen and (max-width: 300px)': {
            display: 'none !important',
            fontSize: '14px !important',
        },
    };

    const navStyle = {
        fontFamily: 'Archivo, sans-serif',
        fontSize: '24px',
        fontWeight: 300,
        lineHeight: '26.11px',
        textAlign: 'left'

    };

    const numStyle = {
        fontFamily: 'Archivo, sans-serif',
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '26.11px',
        textAlign: 'center'

    };


    return (
        <>
            <header>
                <Container fluid className="py-2 d-none d-md-block d-lg-block d-xl-block" style={{
                    height: '52px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#000', color: '#fff'
                    // backgroundColor:'rgb(229, 228, 226,0.6)' 
                }}>
                    <Row className="px-5 ">
                        <Col xs={9} sm={9} md={8} lg={8} xl={8}>
                            <ul className="navbar-nav d-flex flex-row align-items-center justify-content-start gap-5">
                                <NavItem style={navStyle} className='navclass'>
                                    <NavLink className=" m-0 p-0" style={{ cursor: "pointer" }} onClick={() => navigate("/SafeThePlanet")} >#SafeThePlanet</NavLink>
                                </NavItem>
                                <NavItem style={navStyle} className='navclass'>
                                    <NavLink className=" m-0 p-0 " style={{ cursor: "pointer" }} onClick={() => navigate("/SafeThePlanet")}>Research and Insights</NavLink>
                                </NavItem>
                                <NavItem style={navStyle} className='navclass'>
                                    <NavLink className=" m-0 p-0" style={{ cursor: "pointer" }} onClick={() => navigate("/careers")} >Careers</NavLink>
                                </NavItem>

                            </ul>

                        </Col>
                        <Col xs={3} sm={3} md={4} lg={4} xl={4} className="d-flex flex-row-reverse align-items-center align-items-lg-start">
                            <ul className="navbar-nav d-flex flex-row align-items-center">
                                <NavItem style={numStyle} className='navclass'>
                                    (551) 599 0700
                                </NavItem>
                            </ul>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className=" d-block d-md-none d-lg-none d-xl-none" style={{
                    height: '52px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#000', color: '#fff'
                    // backgroundColor:'rgb(229, 228, 226,0.6)' 
                }}>
                    <div className="smallNavbar col-sm-12 col-xs-6 d-flex justify-content-end align-items-center">
                        <a className="close-btn" onClick={handleNavCollapse}>
                            <img src={'/Assets/menu-burger (1) 1.svg'} alt="menu-burger" className='mb-5' />
                        </a>
                    </div>



                    <div className={`col-xs-6 col-sm-12 sidebar ${isSidebarVisible ? "show" : ""}`}>
                        <div className="header d-flex justify-content-end align-items-center">

                            <a className="close-btn mb-2 me-5" onClick={handleCloseSidebar} style={{ color: "black" }}>
                                <img width="40" height="40" src="https://img.icons8.com/ios-filled/50/long-arrow-right.png" alt="long-arrow-right" />
                            </a>
                        </div>
                        <div className="menu-items">
                            <Link to="/" style={{ textDecoration: 'none' }}> <div className="menu-item" onClick={handleCloseSidebar}>Home</div></Link>
                            <Link to="/offer" style={{ textDecoration: 'none' }}><div className="menu-item"onClick={handleCloseSidebar}>Virtual Assistants</div></Link>
                            <Link to="/how-its-works" style={{ textDecoration: 'none' }}><div className="menu-item"onClick={handleCloseSidebar}>How It Works</div></Link>
                            <Link to="/contact-us" style={{ textDecoration: 'none' }}><div className="menu-item"onClick={handleCloseSidebar}>Contact Us</div></Link>
                            <Link to="/careers" style={{ textDecoration: 'none' }}><div className="menu-item"onClick={handleCloseSidebar}>Careers</div></Link>
                            <Link to="/SafeThePlanet" style={{ textDecoration: 'none' }}><div className="menu-item"onClick={handleCloseSidebar}>Safe The Planet</div></Link>
                            <Link to="/offer" style={{ textDecoration: 'none' }}><div className="menu-item"onClick={handleCloseSidebar}>Research and Insights</div></Link>
                      
                
                        </div>
                        <div className="footer">
                        <p style={{ marginBottom: '12px', fontSize:"15px", textDecoration:"none", color:"black"}}>Book a free session</p>
                            <NavLink tag={Link} style={{ textDecoration: "none" }} className="navBtn text-dark text-center d-flex flex-column justify-content-center" to="/counsel-session" >
                                <Button
                                    color="dark"
                                    className=" py-2"
                                    style={{
                                        borderRadius: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '20px 30px 20px 30px',
                                        margin: 'auto',
                                        width: '208px',
                                        height: '45px'


                                    }}
                                    onClick={handleCloseSidebar}
                                >

                                    <span style={{ fontFamily: "Archivo, sans-serif", verticalAlign: 'middle', fontSize: '20px', paddingInlineEnd: '5px' }}>Expert Counsel</span>
                                </Button>
                            </NavLink>

                            <div className="contact-info">
                                <div className=" d-flex justify-content-center align-items-center" style={{ gap: '0.88rem' }}>
                                    <p className={` mb-0 font-weight-normal text-dark`} style={numStyle} > (551) 599 0700</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </header>

        </>

    );
};

export default NavMenu; 
