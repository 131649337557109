import React from 'react';
import { Row, Col } from 'react-bootstrap';

import home from '../Images/Homepage.png';



const Home = () => {

    return (
        <>
      

            <div style={{ flex: 1, minHeight: 'calc(100vh - 242px)' }} className='mt-md-0 mt-lg-0 mt-xl-0 mt-5 mb-2 mb-md-0 mb-lg-0 mb-xl-0'>
                <Row className="d-flex flex-column justify-content-center align-items-center mt-0">
                    <Col xs={12} sm={12} md={7} lg={7} xl={3} className='d-flex justify-content-center p-0 mt-0 mb-5'>
                        <img src={home} alt="home" className="img-fluid "  />
                    </Col>
                    <Col xs={12} md={10} lg={9} xl={7}>
                        <div className="text-center ">
                            <p
                                style={{
                                    fontFamily: "Archivo, sans-serif",
                                    fontSize: "24px",
                                    fontStyle: 'italic',
                                    fontWeight: 400,
                                    lineHeight: "26.11px",
                                    textAlign: 'center'

                                }}
                            ><i><b
                                style={{
                                    fontFamily: "Archivo, sans-serif",
                                    fontSize: "24px",
                                    fontStyle: 'italic',
                                    fontWeight: 700,
                                    lineHeight: "26.11px",
                                    textAlign: 'center'
                                }}
                            >
                                A Digital Marketing Associate could dramatically increase your sales.<br/></b>We strive to be the best. But don’t take our word for it - discover what <br/><b>InstaRep</b> can do for you.</i>
                            </p>
                        </div>
                    </Col>
                    {/* <Col xs={12} md={4}>
                        <NavLink tag={Link} className="text-dark text-center d-flex flex-column justify-content-center mt-2 mb-5" to="https://wa.me/15517990700" target='_blank'>
                            <p style={{ marginBottom: '-3px' }}>Make a free</p>
                            <Button
                                color="dark"
                                className="px-4 py-1"
                                style={{
                                    borderRadius: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '5px 10px 5px 10px',
                                    margin: 'auto',

                                }}

                            >

                                <span style={{ verticalAlign: 'middle', fontSize: '22px', paddingInlineEnd: '5px' }}>Discovery Call</span>
                            </Button>
                        </NavLink>
                    </Col> */}
                </Row>
            </div >
        </>
    );
};

export default Home;