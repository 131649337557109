import { Container, Row, Col } from 'react-bootstrap';

import image from '../Images/1111-removebg-preview.png';
import flag from '../Images/flag.png';
import ResponsiveNav from './ResponsiveNav';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { NavbarBrand, NavItem } from 'reactstrap';
import { useNavGrid } from '../Context/NavGridContext';
import MenuIcon from '../Images/NavIcon.png';
import GridLinks from "./GridLinks";
import "./Offer.css";
import styled from 'styled-components';

const StyledParagraph = styled.p`
width: 50%; // Default width for larger devices
 font-family: Archivo, sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 15.23px;
text-align: center;
width:fit-content;
`;

const StyledHeading = styled.h1`
 font-family: Archivo, sans-serif;
font-size: 36px;
font-weight: 700;
line-height: 39.17px;
text-align: center;
width:fit-content;
`;

const Line = styled.div`
background-color: #000;
width: 202px;
height: 1px;
gap: 0px;
border: 1px 0px 0px 0px;
opacity: 0px;
    @media (min-width: 767px) and (max-width:991px ) {
    /* Media query for smaller devices */
 width: 141px;
  }
`;


const StyledImage = styled.img`
max-height: 90.87px;
  width: ${props => props.width || '172px'};

  border: 2.73px solid transparent; /* Assuming you want a transparent border */

`;

const StyledContainer = styled.div`
width: 402px;
height: 200px;
border: none;
display:flex;
align-items:center;
justify-content:flex-start;
flex-direction:column;
gap:16px;
 padding-top: ${props => props.paddingTop || '0px'};
    @media (min-width: 767px) and (max-width:991px ) {
    /* Media query for smaller devices */
 width: 300px;
  }

`;

export const ContactUsComponent = ({ img, para, alt, width, paddingTop }) => {
    return (
        <>
            <StyledContainer paddingTop={paddingTop}>
                <StyledImage src={img} alt={alt} width={width} />
                <Line></Line>
                <StyledParagraph>{para}</StyledParagraph>
            </StyledContainer>

        </>
    );
};

const LocationsData = [
    {
        img: "/Assets/icon _statue of liberty_.svg",
        para: "Lembeck Avenue, Jersey City,NJ7305, United States",
        alt: "statue of liberty",
        width: "66px",
        paddingTop: "0px",

    },
    {
        img: "/Assets/icon _Place of Worship_.svg",
        para: "Safeer Suites Building Madinat Sultan Qaboos, Muscat Sultanate of Oman",
        alt: "Place of Worship",
        width: "68px",
        paddingTop: "41px",

    },
    {
        img: "/Assets/icon _pakistan_.svg",
        para: "1st floor, ISE Towers Jinnah Avenue, Blue Area Islamabad, Pakistan",
        alt: "pakistan",
        width: "72px",
        paddingTop: "37px",


    },
    {
        img: "/Assets/emoji _palm tree_.svg",
        para: "Wadi Mthlah Street Riyadh Ghirnatah Saudi Arabia",
        alt: "palm tree",
        width: "55px",
        paddingTop: "34px",

    },
]

const ContactUs = () => {
    const { showGrid } = useNavGrid();

    const logoStyle = {
        fontSize: '24px',
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        alignItems: 'center', // Align text vertically in the center

    };

    const instaStyle = {
        fontWeight: 'normal', // Normal font weight for "insta"
        fontStyle: 'normal',
    };

    const repStyle = {
        fontWeight: 'bold', // Bold font weight for "Rep"
        marginRight: '5px',
        fontStyle: 'normal',
    };
    const openCalendar = () => {
        window.open(
            'https://calendar.google.com/calendar/appointments/schedules/AcZssZ306Wd41e_mMfMFfelHnUTybTLsc2CenXAGWXwBGxQo6FXPD2fDiUbaPOI9Rx45BzvSD5SBDo-y?gv=true',
            '_blank'
        );
    };

    return (
        <>

            <div style={{ flex: 1, minHeight: 'calc(100vh - 50px)' }}>

                <Container className="d-flex  flex-column justify-content-center  align-items-center mt-3 mb-md-5 gap-5">

                    <Row className="d-flex flex-row justify-content-center align-items-center w-100">

                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className='ps-0  ps-lg-5 ps-xl-5 m-0 d-flex flex-column justify-content-center align-items-start mt-3 mt-md-0 mt-lg-0 mt-xl-0'>
                            <StyledHeading style={{ textAlign: 'left' }}>
                                Contact Us
                            </StyledHeading>
                            <StyledParagraph style={{ textAlign: 'left', width: '100%' }}>
                                We aim to be your trusted advisor for expansion, guiding you through <br />
                                sharing valuable insights, and fostering a long-term partnership <br />
                                that helps your business establish a strong presence.
                                <br /><br />
                                Submit the form or contact us on any of the following address.<br />
                                We shall be pleased to responsd you
                            </StyledParagraph>

                            <NavLink tag={Link} className="text-dark text-center d-flex flex-column justify-content-center mt-5 mb-5" onClick={openCalendar}>

                                <Button
                                    color="dark"
                                    className=" py-2"
                                    style={{
                                        borderRadius: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '20px 30px 20px 30px',
                                        margin: 'auto',
                                        width: '208px',
                                        height: '45px'


                                    }}

                                >

                                    <span style={{ fontFamily: "Archivo, sans-serif", verticalAlign: 'middle', fontSize: '20px', paddingInlineEnd: '5px' }}>Expert Counsel</span>
                                </Button>
                                <p style={{ marginBottom: '-3px', fontSize: "15px" }}>Book a free session</p>
                            </NavLink>



                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="p-0 mt-0">
                            <div style={{ position: 'relative', width: '100%', paddingTop: '75%' }}>
                                <iframe
                                    src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FNew_York&bgcolor=%23ffffff&src=Y291bnNlbEBpbnN0YXJlcC5jbw&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4ucGsjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%2333B679&color=%230B8043"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        border: 'solid 1px #777',
                                    }}
                                    frameBorder="0"
                                    title="Google Calendar"
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="col-12 d-flex flex-rowalign-items-center justify-content-center flex-wrap p-0">
                        {LocationsData.map((element, index) => (
                            <>
                                <Col xs={12} sm={12} md={3} lg={3} xl={3} key={index} className="d-flex justify-content-center align-items-end mx-0 px-0">
                                    <ContactUsComponent
                                        alt={element.alt}
                                        img={element.img}
                                        para={element.para}
                                        width={element.width}
                                        paddingTop={element.paddingTop}
                                    />
                                </Col>
                            </>
                        ))}
                    </Row>

                </Container>
            </div>
        </>
    );
};

export default ContactUs;
