import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useNavbar } from '../Context/NavabarContext';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
const TopNav = styled.div`
  overflow: hidden;
  text-align: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;

  @media (max-width: 767px) {
    display: none;
  }
 
`;

const StyledNavLink = styled(NavLink)`
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 48px;
  text-decoration: none;
  font-size: 19px;
  border-bottom: 1px solid gray;

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 10px 28px;
  }

    @media (min-width: 768px) and (max-width: 991px) {
         font-size: 12px;
    padding: 16px; 
        }


    @media (min-width: 991px) and (max-width: 1235px) {
  font-size: 18px;
    padding: 18px; 
  }

  &:hover {
    border-bottom: 2px solid black;
  }

  &.active {
    border-bottom: 2px solid black;
  }

  @media (max-width: 767px) {
    float: none;
    width: 100%;
    box-sizing: border-box;

    &.active {
      background-color: #ddd;
    }
  }
`;

const MobileNav = styled.div`
  display: none;
  
  @media (max-width: 767px) {
    justify-content: flex-start;
    text-align:left;
    margin-left: 0px !important;
    display: block;
    width: 100%;
 
  }
`;

const Dropdown = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

// const GoogleCalendarButton = () => {
//   useEffect(() => {
//     // Load the Google Calendar scheduling script
//     const script = document.createElement('script');
//     script.src = 'https://calendar.google.com/calendar/scheduling-button-script.js';
//     script.async = true;
//     document.body.appendChild(script);

//     script.onload = () => {
//       const target = document.getElementById('calendar-button-container');
//       if (window.calendar && target) {
//         window.calendar.schedulingButton.load({
//           url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ306Wd41e_mMfMFfelHnUTybTLsc2CenXAGWXwBGxQo6FXPD2fDiUbaPOI9Rx45BzvSD5SBDo-y?gv=true',
//           color: '#039BE5',
//           label: 'Book an appointment',
//           target: target,
//         });
//       }
//     };
//   }, []);

// };

const openCalendar = () => {
  window.open(
    'https://calendar.google.com/calendar/appointments/schedules/AcZssZ306Wd41e_mMfMFfelHnUTybTLsc2CenXAGWXwBGxQo6FXPD2fDiUbaPOI9Rx45BzvSD5SBDo-y?gv=true',
    '_blank'
  );
};



const ResponsiveNav = () => {
  const { isOpen, selectedOption, toggleDropdown, handleNavLinkClick } = useNavbar();

  return (
    <>
      <TopNav>
        <StyledNavLink
          exact
          to="/"
          onClick={() => handleNavLinkClick('HOME')}
        >
          HOME
        </StyledNavLink>
        <StyledNavLink
          to="/offer"
          onClick={() => handleNavLinkClick('OUR ROLES')}
        >
          VIRTUAL ASSISTANTS
        </StyledNavLink>
        <StyledNavLink
          to="/how-its-works"
          onClick={() => handleNavLinkClick('FRAMEWORK')}
        >
          HOW IT WORKS
        </StyledNavLink>
        <StyledNavLink
          to="/contact-us"
          onClick={() => handleNavLinkClick('CONTACT US')}
        >
          CONTACT US
        </StyledNavLink>
        <NavLink tag={Link} style={{marginBottom:"-38px", marginLeft:"75px", textDecoration:"none"}}className="navBtn text-dark text-center d-flex flex-column justify-content-center"  onClick={openCalendar}>

          <Button
            color="dark"
            className=" py-2"
            style={{
              borderRadius: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '20px 30px 20px 30px',
              margin: 'auto',
              width: '208px',
              height: '45px'


            }}

          >

            <span style={{ fontFamily: "Archivo, sans-serif", verticalAlign: 'middle', fontSize: '20px', paddingInlineEnd: '5px' }}>Expert Counsel</span>
          </Button>
          <p style={{ marginBottom: '12px', fontSize:"15px", textDecoration:"none" }}>Book a free session</p>
        </NavLink>
        {/* <div id="calendar-button-container">
          <button
            onClick={openCalendar}
            style={{
              backgroundColor: '#039BE5',
              color: '#FFFFFF',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Book an appointment
          </button>
        </div> */}

      </TopNav>
      {/* <MobileNav className="mx-5 mt-3">
        <button onClick={toggleDropdown}
          style={{ border: 'none', textDecoration: 'none', fontSize: '18px', borderBottom: '1px solid gray', backgroundColor: 'transparent' }}
        >{selectedOption || 'Menu'}</button>
        <Dropdown isOpen={isOpen}>
          <StyledNavLink
            exact
            to="/"
            onClick={() => handleNavLinkClick('HOME')}
          >
            HOME
          </StyledNavLink>
          <StyledNavLink
            to='/offer'
            onClick={() => handleNavLinkClick('OUR ROLES')}
          >
            OUR ROLES
          </StyledNavLink>
          <StyledNavLink
            to="/framework"
            onClick={() => handleNavLinkClick('FRAMEWORK')}
          >
            FRAMEWORK
          </StyledNavLink>
          <StyledNavLink
            to="/why-instarep-home"
            onClick={() => handleNavLinkClick('WHY INSTAREP')}
          >
            WHY INSTAREP
          </StyledNavLink>
          <StyledNavLink
            to="/contact-us"
            onClick={() => handleNavLinkClick('CONTACT US')}
          >
            CONTACT US
          </StyledNavLink>
        </Dropdown>
      </MobileNav> */}
    </>
  );
};

export default ResponsiveNav;
