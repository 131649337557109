import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const numStyle = {
    fontFamily: 'Archivo, sans-serif',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '25.02px',
    textAlign: 'center'

};

const CounselSession = () => {
    const navigate = useNavigate();
    return (
        <>
            <div style={{ flex: 1, minHeight: 'calc(100vh - 50px)' }} className="mt-5 mt-md-0">
                <div style={{ marginBottom: "25px", marginTop: "25px", marginRight: "35px", marginLeft: "35px" }}>

                    <Container className=" d-flex flex-column align-items-center justify-content-center">
                        <Row className='my-5'>
                            <p style={numStyle}>
                            Trust has become more vital than ever. It serves as the bridge that connects your 
                            organization with its people, customers, stakeholders, and the broader world. 
                            Trust isn't something that can be purchased or obtained instantly; 
                            it's built and earned through consistent actions, meaningful interactions, 
                            positive experiences, and the successful delivery of outcomes. </p>
                        </Row>
                        <Row className=" d-flex flex-row align-items-center justify-content-center p-0 m-0" style={{width:'100%'}}>
                            <Col className="text-center mt-3" xs={12} sm={12} lg={5} xl={5} md={5}>
                            <div style={{ backgroundColor: "gray", width:"867px", maxWidth: "100%", height: "359px" }}></div>
                            </Col>
                
                        </Row>


                    </Container>


                </div>
            </div>

        </>
    );
};


export default CounselSession;